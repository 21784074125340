import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['select']

  // connect() { }

  update(event) {
    const package_id = event.target.value
    const url = event.target.dataset['url']

    $.ajax({
      url: url,
      method: "GET",
      data: {
        "new_package_id" : package_id
      }
     })
  }
}
