import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['select']

  // connect() { }

  filter(event) {
    const keyword = event.target.value
    const options = this.selectTarget.options

    for (let i = 0; i < options.length; i++) {

      console.log("key: ", keyword, ("result " + i + ": "), options[i].innerHTML.toLowerCase().search(keyword.toLowerCase()) > -1)

      if (options[i].innerHTML.toLowerCase().search(keyword.toLowerCase()) > -1){
        options[i].style.display = "block"
      } else {
        options[i].style.display = "none"
      }
    }
  }
}
